import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "manage-api-keys--access"
    }}>{`Manage API Keys & Access`}</h1>
    <p>{`Your events platform features a collection of powerful API endpoints that allow you to build endless integrations between it and your existing systems. Libraries use these to embed events directly into their website, build the calendar natively into their mobile apps and so much more.`}</p>
    <Alert kind="warning" mdxType="Alert">This functionality is exclusively available to users with Admin level access permissions.</Alert>
    <h2 {...{
      "id": "manage-api-keys"
    }}>{`Manage API Keys`}</h2>
    <ol>
      <li parentName="ol">{`Click Advanced in the left menu`}</li>
      <li parentName="ol">{`Click the Manage API Keys button shown in red under the page's heading`}</li>
      <li parentName="ol">{`Use the Create New API Key button to generate a new key, this can be used by a developer or library partner to create an integration. Your developer will also need the API Base URL shown above the the Create New API Key button.`}</li>
      <li parentName="ol">{`Use the DELETE button to delete existing API keys`}</li>
    </ol>
    <h3 {...{
      "id": "api-documentation"
    }}>{`API Documentation`}</h3>
    <p>{`Extensive API documentation and code examples are available to help developers. The link to these resources is available above API Base URL on the API Keys page.`}</p>
    <Alert kind="warning" mdxType="Alert">API keys provide third parties with complete access to your calendar platform. Please protect API keys and only provide them to authorised individuals.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      